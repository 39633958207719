import { CustomButtonOne } from "@components";
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { getUserProfile } from "@store/actions/authActions";
import axios from "config/axios";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    background: "#fff",
    borderRadius: 8,
    width: "100%",
    padding: "30px 14px",
  },
  userImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ratingStyle: {
    color: theme.palette.primary.main,
  },
  iconsWrapper: {
    background: theme.palette.primary.main,
    width: 40,
    height: 40,
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reviewChip: {
    background: theme.palette.primary.main,
    padding: "8px 12px",
    color: "#fff",
    borderRadius: 20,
    marginTop: 10,
    marginRight: 10,
  },
  textAreaStyle: {
    width: "100%",
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",

    padding: "10px",
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
  },
}));

const schema = yup.object().shape({
  about: yup.string(),
});

function MyProfileCard({ profile, stats, details, getUserProfile, token }) {
  const classes = useStyles();
  const [showForm, setShowForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(details?.about);
  }, [details]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `/setting/detail-info/user/${profile?.id}`,
        {
          about: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        getUserProfile(token);
        setLoading(false);
        setShowForm(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.userImage}>
        <Avatar
          src={profile && profile?.user_detail[0]?.avatar}
          alt={profile && profile?.first_name}
          style={{
            width: 155,
            height: 155,
          }}
        />
      </div>
      <Typography
        variant="body1"
        color="textPrimary"
        style={{ margin: "20px 0px", textAlign: "center" }}
      >
        {profile && profile?.first_name}
      </Typography>
      {profile && profile?.user_detail[0]?.state && (
        <Grid container alignItems="center" justify="center">
          <LocationOnOutlinedIcon color="primary" style={{ fontSize: 18 }} />
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ marginLeft: 5 }}
          >
            {profile && profile?.user_detail[0]?.state},{" "}
            {profile && profile?.user_detail[0]?.city}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ margin: "20px 0px" }}
      >
        <CustomButtonOne component={Link} to="/account-settings">
          Edit profile
        </CustomButtonOne>
      </Grid>

      <div
        style={{
          width: "100%",
          height: 1,
          background: "#EDEDED",
          margin: "10px 0",
        }}
      />

      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ margin: "20px 0px" }}
      >
        <Typography variant="body1" color="textPrimary">
          Participations
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {(stats && stats?.participation) || 0}
        </Typography>
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ margin: "20px 0px" }}
      >
        <Typography variant="body1" color="textPrimary">
          Victories
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {(stats && stats?.victories) || 0}
        </Typography>
      </Grid>

      <div
        style={{
          width: "100%",
          height: 1,
          background: "#EDEDED",
          margin: "10px 0",
        }}
      />

      {/* <Grid container alignItems="center" justify="space-between" style={{ margin: '20px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                    Float Points
                </Typography> 
                <Typography variant="body1" color="textPrimary">
                    {stats && stats?.float_points || 0}
                </Typography> 
            </Grid> */}

      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ margin: "20px 0px" }}
      >
        <Typography variant="body1" color="textPrimary">
          Float Coins
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {(stats && stats?.float_bucks) || 0}
        </Typography>
      </Grid>

      <div
        style={{
          width: "100%",
          height: 1,
          background: "#EDEDED",
          margin: "10px 0",
        }}
      />

      {/* <Grid container alignItems="center" style={{ margin: "20px 0px" }}>
        <FacebookIcon color="primary" />
        <InstagramIcon color="primary" style={{ marginLeft: 10 }} />
        <TwitterIcon color="primary" style={{ marginLeft: 10 }} />
      </Grid> */}

      {/* <div
        style={{
          width: "100%",
          height: 1,
          background: "#EDEDED",
          margin: "10px 0",
        }}
      /> */}

      <Typography variant="body1" color="textPrimary">
        About Me
      </Typography>
      <form
        onSubmit={submitHandler}
        autoComplete="off"
        style={{ marginTop: 10, display: showForm ? "block" : "none" }}
      >
        <textarea
          className={classes.root}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="About..."
        />
        <Grid container alignItems="center" justify="flex-end">
          <IconButton
            style={{ padding: "4px" }}
            onClick={() => setShowForm(false)}
          >
            <CloseIcon color="inherit" style={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            style={{ padding: "4px" }}
            type="submit"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <CheckIcon color="inherit" style={{ fontSize: "20px" }} />
            )}
          </IconButton>
        </Grid>
      </form>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{ marginTop: 10, display: showForm ? "none" : "block" }}
      >
        {details && details?.about}
        <span style={{ marginLeft: "15px" }}>
          <IconButton
            style={{ padding: "4px" }}
            onClick={() => setShowForm(true)}
            color="primary"
          >
            <EditIcon color="inherit" style={{ fontSize: "20px" }} />
          </IconButton>
        </span>
      </Typography>
    </div>
  );
}

const mapStateToProps = (state) => ({
  details: state.auth.details,
  token: state.auth.token,
});

export default connect(mapStateToProps, { getUserProfile })(MyProfileCard);
