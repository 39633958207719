import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { Typography, Grid, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

import { closeModal } from "../modalActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JoinersModal({ floatJoiners, closeModal }) {
  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography
            variant="h5"
            color="primary"
            style={{ textAlign: "center" }}
          >
            List of Participants
          </Typography>
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            <CloseIcon color="primary" />
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: "30px" }}>
          <Grid
            container
            alignItems="center"
            justify="space-evenly"
            spacing={3}
            style={{ marginTop: 10 }}
          >
            {floatJoiners?.map((item) => (
              <div
                key={item?.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <Avatar
                  key={item?.id}
                  src={item?.user_id?.user_detail[0]?.avatar}
                  alt={item?.user_id?.first_name}
                />
                <Typography>{item?.user_id?.first_name}</Typography>
              </div>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalProps: state.modal.modalProps,
});

export default connect(mapStateToProps, {
  closeModal,
})(JoinersModal);
