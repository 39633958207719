import React from "react";
import { makeStyles } from "@material-ui/core";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Loader } from "@components";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "90vh",
    // [theme.breakpoints.down("sm")]: {
    //   height: "60vh",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: "50vh",
    // },
  },
  image_container: {
    height: "90vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
  },
  loaderStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoStyle: {
    width: "100%",
    maxHeight: "720px",
    background: "#000",
    [theme.breakpoints.down(1100)]: {
      height: "450px",
    },
    [theme.breakpoints.down(991)]: {
      height: "350px",
    },
    [theme.breakpoints.down(570)]: {
      height: "270px",
    },
  },
}));

function BannerSlider({ url, showcontrol }) {
  const classes = useStyles();

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
  };
  return (
    <div className={classes.root}>
      <video
        src={url}
        muted={!showcontrol}
        autoPlay={!showcontrol}
        loop
        controls={showcontrol}
        controlsList="nodownload noremoteplayback"
        className={classes.videoStyle}
      ></video>
      {/* {data ? (
                <Slide {...properties}>
                    {data.map(item => (
                        <div className="each-slide" key={item.id}>
                            <div 
                                style={{'backgroundImage': `url(${item.image})`}}
                                className={classes.image_container}
                            >
                                <Typography variant="h1" color="secondary">{img.caption}</Typography>
                            </div>
                        </div>
                    ))}
                </Slide>
            ): (
                <div className={classes.loaderStyle}>
                    <Loader />
                </div>
            )} */}
    </div>
  );
}

export default BannerSlider;
