import { combineReducers } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import asyncReducer from '@store/reducers/asyncReducer';
import userReducer from '@store/reducers/userReducer';
import homeReducer from '@store/reducers/homeReducer';
import productReducer from '@store/reducers/productReducer';
import cartReducer from '@store/reducers/cartReducer';
import modalReducer from '@modals/modalReducer';

export default combineReducers({
    auth: userReducer,
    home: homeReducer,
    product: productReducer,
    cart: cartReducer,
    async: asyncReducer,
    toastr: toastrReducer,
    modal: modalReducer
});