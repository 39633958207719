import * as actions from '../actionTypes';

const initialState = {
    loading: false,
    progress: 0
}

export default (state=initialState, action) => {
    switch (action.type) {
        case actions.ASYNC_START_LOADING:
            return {
                ...state,
                loading: true
            }
        case actions.ASYNC_FINISHED_LOADING:
            return {
                ...state,
                loading: false
            }
        case actions.UPLOAD_PERCENTAGE:
            return {
                ...state,
                progress: action.payload.progress
            }
        case actions.CLEAR_UPLOAD_PERCENTAGE:
            return {
                ...state,
                progress: 0
            }
        default:
            return state;
    }
}