import * as actions from "../actionTypes";

const initialState = {
  brands: null,
  categories: null,
  queryData: null,
  keywords: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PRODUCT_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case actions.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actions.GET_SEARCH_PRODUCTS:
      return {
        ...state,
        queryData: action.payload,
      };
    case actions.SET_SEARCH_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
      };
    default:
      return state;
  }
};
