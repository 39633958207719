import { makeStyles, TextField, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: "2.5px 4px",
      },
  },
  labelStyle: {
    ...theme.typography.button,
  },
  icon: {
    position: "absolute",
    top: 45,
    right: 5,
    width: 30,
    height: 30,
    background: "#fff",
    zIndex: 999,
  },
}));

// const options = [
//     { title: 'The Shawshank Redemption', year: 1994 },
//     { title: 'The Godfather', year: 1972 },
//     { title: 'The Godfather: Part II', year: 1974 },
//     { title: 'The Dark Knight', year: 2008 },
//     { title: '12 Angry Men', year: 1957 },
//     { title: "Schindler's List", year: 1993 },
//     { title: 'Pulp Fiction', year: 1994 }
// ]

function CustomSelect({ label, options, onInputChange, getOptionLabel }) {
  const classes = useStyles();
  return (
    <div style={{ position: "relative" }}>
      <Typography
        variant="body1"
        color="textPrimary"
        className={classes.labelStyle}
      >
        {label}
      </Typography>
      <Autocomplete
        id={label}
        // freeSolo
        popupIcon={<ArrowDropDownIcon color="primary" />}
        options={options}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            className={classes.root}
          />
        )}
      />
      {/* <div className={classes.icon}>
                <ArrowDropDownIcon color="primary" />
            </div> */}
    </div>
  );
}

export default CustomSelect;
