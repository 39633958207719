import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
import rootReducer from './rootReducer'
 

const persistConfig = {
  key: 'floatthat',
  storage,
  whitelist: ['auth', 'cart']
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
export const store = createStore(persistedReducer, composeWithDevTools(
    applyMiddleware(thunk)
));
export const  persistor = persistStore(store)