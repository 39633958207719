import * as actions from "../actionTypes";

const initialState = {
  cartList: [],
  subTotal: 0,
  discount: 0,
  appliedFloatBucks: 0,
  grandTotal: 0,
  redirectUrl: {
    status: false,
    url: "#",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_TO_CART:
      return {
        ...state,
        cartList: action.payload,
      };
    case actions.SUBTOTAL_PRICE:
      return {
        ...state,
        subTotal: action.payload.subTotalPrice,
        discount: action.payload.discountPrice,
      };
    case actions.SET_FLOAT_BUCKS:
      return {
        ...state,
        appliedFloatBucks: action.payload,
      };
    case actions.GRANDTOTAL_PRICE:
      return {
        ...state,
        grandTotal: action.payload,
      };
    case actions.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case actions.CLEAR_CART_ITEMS:
      return {
        ...state,
        cartList: [],
      };
    case actions.CLEAR_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: {
          status: false,
          url: "#",
        },
      };
    default:
      return state;
  }
};
