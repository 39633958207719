import { makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  root: {
    width: "100%",
    // height: 214,
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",

    padding: "10px",
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
  },
}));

function TextArea({ label, ...rest }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {label && (
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ marginBottom: 10 }}
        >
          {label}
        </Typography>
      )}
      <TextField
        {...rest}
        multiline
        rows={2}
        autoComplete="off"
        // variant="outlined"
        className={classes.root}
        InputProps={{ disableUnderline: true }}
      />
      {/* <textarea {...rest} className={classes.root} /> */}
    </div>
  );
}

export default TextArea;
