import { CustomButtonOne } from "@components";
import { makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { getNgoList } from "@store/actions/homeActions";
import axios from "config/axios";
import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import UnicefDetails from "../components/UnicefDetails";
import { closeModal } from "../modalActions";
import SelectInput from "./NgoSelectInput";

const useStyles = makeStyles((theme) => ({
  selectStyle: {
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SelectNgo({ getNgoList, closeModal, product, token }) {
  const classes = useStyles();
  const [ngoList, setNgoList] = React.useState(null);
  const [selected, setSelected] = React.useState(null);

  const { isFromCard, cardDetails } = product;

  React.useEffect(() => {
    //get ngo list
    getNgoList()
      .then((res) => {
        if (res.data.count > 0) {
          setNgoList(res.data.results);
          setSelected(res.data.results[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (isFromCard) {
      const data = ngoList?.filter(
        (ele) => ele.id === parseInt(product?.cardDetails?.ngo_id?.id)
      )[0];
      setSelected(data);
    }
  }, [ngoList, cardDetails]);

  const handleChange = (e) => {
    const data = ngoList?.filter(
      (ele) => ele.id === parseInt(e.target.value)
    )[0];
    setSelected(data);
  };

  const tokenHandler = (stripeToken) => {
    const data = {
      ngo_id: selected?.id,
      product_id: product?.results[0]?.id,
      stripe_token: stripeToken?.id,
    };
    axios
      .post("/float/join/", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        toastr.success(res.data.status);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let isFromProduct = false;
  if (product?.results[0]?.ngos?.length > 0) {
    isFromProduct = true;
  } else {
    isFromProduct = false;
  }

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle style={{ textAlign: "right", padding: 0 }}>
          <IconButton
            style={{
              marginLeft: "auto",
              padding: 5,
            }}
            onClick={closeModal}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            color="textPrimary"
            variant="h5"
            style={{ textAlign: "center" }}
          >
            Select NGO to donate
          </Typography>
          <div className={classes.selectStyle}>
            <SelectInput
              options={isFromProduct ? product?.results[0]?.ngos : ngoList}
              onChange={handleChange}
              selectedId={
                product?.isFromCard && product?.cardDetails?.ngo_id?.id
              }
              isFromCard={product?.isFromCard ? product?.isFromCard : false}
            />
          </div>
          <br />
          <UnicefDetails data={selected ? selected : null} />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <StripeCheckout
            token={tokenHandler}
            stripeKey="pk_test_51HS0wcElUHHiEBNgrM6mo0QCMYU60KSYXcOsy991ZyFXTItc29KFXSDd7c9WYJBFJte8LxxostqIMYSLXncr7bn0004Kt8wDJo"
            name={product?.results[0]?.name}
            amount={product?.results[0]?.price * 100}
            // currency="Rs"
            // closed={() => window.location.reload()}
          >
            <CustomButtonOne onClick={closeModal}>Donate now</CustomButtonOne>
          </StripeCheckout>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  product: state.modal.modalProps,
  token: state.auth.token,
});

export default withRouter(
  connect(mapStateToProps, {
    closeModal,
    getNgoList,
  })(SelectNgo)
);
