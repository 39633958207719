import { toastr } from "react-redux-toastr";
import axios from "../../config/axios";

export const getFloatDetailsById = (floatID) => (dispatch) =>
  axios.get(`/float/detail/${floatID}`);

export const getSimilarFloatById = (floatID) => (dispatch) =>
  axios.get(`/float/similar-list/${floatID}?size=3`);

export const getProductRatingById = (productID) => (dispatch) =>
  axios.get(`/rating/list/product/${productID}`);

export const getFloatCommentsById = (floatID) => (dispatch) =>
  axios.get(`/float/comment/list/${floatID}`);

export const addFloatCommentById = (creds) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      return axios.post("/float/comment/create/", creds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      toastr.error("Please login to comment.");
    }
  };
};
