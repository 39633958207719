import React from 'react';
import { Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';

function Desc({ data }) {
    return (
        <motion.div
            initial={{
                opacity: 0.7,
                transform: 'scale(0.7)'
            }}
            animate={{
                opacity: 1,
                transform: 'scale(1)'
            }}
            transition={{
                type: 'tween'
            }}
        >
            <Typography variant="subtitle1" color="textPrimary" style={{ paddingRight: 10 }}>
                {data && parse(data.description)}
            </Typography>
        </motion.div>
    )
}

export default Desc;
