import React from "react";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btnStyle: {
    ...theme.typography.caption,
    background: " linear-gradient(158.81deg, #009FFD 0%, #2A2A72 100%)",
    width: 168,
    height: 40,
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: 41,
    "&:hover": {
      boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
}));

function CustomButtonOne({ children, ...rest }) {
  const classes = useStyles();
  return (
    <Button {...rest} className={classes.btnStyle}>
      {children}
    </Button>
  );
}

export default CustomButtonOne;
