import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  circleDeterminateStyle: {
    color: "#C4C4C4",
  },
}));

function CircularProgressWithOutLabel(props) {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        value={100}
        thickness={6}
        variant="determinate"
        color="primary"
        classes={{
          circleDeterminate: classes.circleDeterminateStyle,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" color="primary">
          {/* {props.value} */}
        </Typography>
      </Box>
    </Box>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        {...props}
        thickness={6}
        variant="determinate"
        color="primary"
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" color="primary">
          {props.value}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic({ value = 0 }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgressWithOutLabel />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <CircularProgressWithLabel value={value} color="primary" />
      </div>
    </div>
  );
}
