import React from "react";
import { connect } from "react-redux";
import LoginPopup from "./modalNames/LoginPopup";
import SelectNgo from "./modalNames/SelectNgo";
import JoinersModal from "./modalNames/JoinersModal";

const modalLookup = {
  LoginPopup,
  SelectNgo,
  JoinersModal,
};

function ModalManager({ modalType, modalProps }) {
  let rendererModal;

  if (modalType) {
    const ModalComponent = modalLookup[modalType];

    rendererModal = <ModalComponent {...modalProps} />;
  }
  return <span>{rendererModal}</span>;
}

const mapStateToProps = (state) => ({
  modalType: state.modal.modalType,
  modalProps: state.modal.modalProps,
});

export default connect(mapStateToProps)(ModalManager);
