// import * as actions from '../actionTypes';
import axios from "../../config/axios";

export const getBannerList = () => (dispatch) => axios.get("/banner/list");

export const getHomeFeature = () => (dispatch) =>
  axios.get("/product/home-feature");

export const getHomeTrendingFloatList = () => (dispatch) =>
  axios.get("/float/featured-list?size=4");

export const getHomeLatestFloatList = () => (dispatch) =>
  axios.get("/float/latest-list?size=4");

export const getHomeCompletedFloatList = () => (dispatch) =>
  axios.get("/float/completed-list?size=4");

export const getHomeBuyNowList = () => (dispatch) =>
  axios.get("/product/latest-list?size=4");

export const getPressReleaseList = () => (dispatch) =>
  axios.get("/pressrelease/list/");

export const getWinnerStories = () => (dispatch) =>
  axios.get("/winners/story/list/");

export const getWinnerStoriesById = (storyId) => (dispatch) =>
  axios.get(`/winner/detail/${storyId}`);

export const getNgoList = () => (dispatch) => axios.get("/ngo/list/?size=100");
