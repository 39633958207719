import { CustomButtonOne } from "@components";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    borderRadius: 8,
    height: "453px",
    position: "relative",
    [theme.breakpoints.down("500")]: {
      width: "100%",
    },
  },
  imgStyle: {
    width: "100%",
    objectFit: "fill",
    borderRadius: "8px 8px 0px 0px",
    height: "100%",
  },
  actionArea: {
    padding: "0px 15px",
    boxSizing: "border-box",
    position: "absolute",
    bottom: 10,
    left: 0,
    width: "100%",
  },
  fixedWidth: {
    width: "361px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  checkMorefixedWidth: {
    width: "353px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function CardTwo({ item, isFixedWidth, isCheckMore, currentUser }) {
  const classes = useStyles();
  const theme = useTheme();

  const showDiscountValue = () => {
    if (item?.discount_for === 0) {
      return (
        <strong style={{ color: theme.palette.primary.main }}>
          <span style={{ marginLeft: "5px" }}>|</span>{" "}
          {item?.has_discount_value}% Off
        </strong>
      );
    } else if (item?.discount_for === 1 && currentUser?.user?.date_joined) {
      if (
        new Date(currentUser?.user?.date_joined).getDate() ===
        new Date().getDate()
      ) {
        return (
          <strong style={{ color: theme.palette.primary.main }}>
            <span style={{ marginLeft: "5px" }}>|</span>{" "}
            {item?.has_discount_value}% Off
          </strong>
        );
      }
    }
  };

  /* 

<strong style={{ color: theme.palette.primary.main }}>
                  <span style={{ marginLeft: "5px" }}>|</span>{" "}
                  {item?.has_discount_value}% Off
                </strong>
  */

  return (
    <motion.div
      className={clsx([
        classes.root,
        isFixedWidth && classes.fixedWidth,
        isCheckMore && classes.checkMorefixedWidth,
      ])}
      initial={{
        opacity: 0.7,
        transform: "scale(0.7)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        type: "tween",
      }}
    >
      <div
        style={{
          height: "340px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <img
          src={
            item?.images.length > 0
              ? item?.images[0].image
              : "/images/no-img.jpeg"
          }
          alt={item?.name}
          className={classes.imgStyle}
        />
      </div>
      <div
        style={{
          padding: "0px 15px",
          boxSizing: "border-box",
          marginTop: 5,
          height: 40,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Typography variant="body2" color="textPrimary">
          {item?.name}
        </Typography>
        {item?.name?.length > 100 && (
          <span
            style={{
              position: "absolute",
              bottom: 2,
              right: 25,
              width: "50px",
              background: "#fff",
            }}
          >
            ...
          </span>
        )}
      </div>
      <div className={classes.actionArea}>
        <Grid container alignItems="center" justify="space-between">
          <Typography variant="body2" color="textPrimary">
            Price: <strong>Rs.{item?.price}</strong>
            {item?.has_discount_offer && <>{showDiscountValue()}</>}
          </Typography>

          <CustomButtonOne
            style={{
              width: 114,
              height: 30,
            }}
            component={Link}
            to={`/product-details/${item?.id}`}
          >
            Buy now
          </CustomButtonOne>
        </Grid>
      </div>
    </motion.div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(CardTwo);
