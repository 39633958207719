//async loading
export const ASYNC_START_LOADING = "ASYNC_START_LOADING";
export const ASYNC_FINISHED_LOADING = "ASYNC_FINISHED_LOADING";
export const UPLOAD_PERCENTAGE = "UPLOAD_PERCENTAGE";
export const CLEAR_UPLOAD_PERCENTAGE = "CLEAR_UPLOAD_PERCENTAGE";

// authentication action types
export const STORE_LOGGEDIN_USER_CREDS = "STORE_LOGGEDIN_USER_CREDS";
export const STORE_LOGGEDIN_USER_PROFILE = "STORE_LOGGEDIN_USER_PROFILE";
export const STORE_LOGGEDIN_USER_DETAILS = "STORE_LOGGEDIN_USER_DETAILS";
export const STORE_LOGGEDIN_USER_PRODUCT_HISTORY =
  "STORE_LOGGEDIN_USER_PRODUCT_HISTORY";
export const STORE_LOGGEDIN_USER_PURCHASE_HISTORY =
  "STORE_LOGGEDIN_USER_PURCHASE_HISTORY";
export const STORE_LOGGEDIN_USER_REVIEW_LIST =
  "STORE_LOGGEDIN_USER_REVIEW_LIST";
export const CLEAR_LOGGEDIN_USER_CREDS = "CLEAR_LOGGEDIN_USER_CREDS";
export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN";

// product actions
export const GET_PRODUCT_BRANDS = "GET_PRODUCT_BRANDS";
export const GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES";
export const GET_SEARCH_PRODUCTS = "GET_SEARCH_PRODUCTS";
export const SET_SEARCH_KEYWORDS = "SET_SEARCH_KEYWORDS";

// cart
export const ADD_TO_CART = "ADD_TO_CART";
export const SUBTOTAL_PRICE = "SUBTOTAL_PRICE";
export const SET_FLOAT_BUCKS = "SET_FLOAT_BUCKS";
export const GRANDTOTAL_PRICE = "GRANDTOTAL_PRICE";
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const CLEAR_REDIRECT_URL = "CLEAR_REDIRECT_URL";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
