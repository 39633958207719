import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    root: {
        background: 'linear-gradient(158.81deg, #009FFD 0%, #2A2A72 100%)',
        padding: 1,
        boxSizing: 'border-box',
        width: 168,
        height: 40,
        borderRadius: 41
    },
    btnStyle: {
        ...theme.typography.caption,
        background: '#fff',
        width: '100%',
        height: '100%',
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
        borderRadius: 41,
        '&:hover': {
            background: '#fff',
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.25)'
        }
    }
}));

function CustomButtonThree({ children, endIcon, ...rest }) {
    const classes = useStyles();
    return (
        <div className={classes.root} { ...rest }>
            <Button
                endIcon={endIcon}
                className={classes.btnStyle}
            >
                {children}
            </Button>
        </div>
    )
}

export default CustomButtonThree;