import * as actions from '../actionTypes';

const initialState = {
    loading: false
}

export default (state=initialState, action) => {
    switch (action.type) {
        // case actions.ASYNC_START_LOADING:
        //     return {
        //         ...state,
        //         loading: true
        //     }
        default:
            return state;
    }
}