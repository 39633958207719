import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    "& .MuiSelect-select:focus": {
      background: "none",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectStyle: {
    width: "100%",
  },
}));

function SelectInput({ options, onChange }) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select native onChange={onChange}>
        <option aria-label="None" value="" />
        {options &&
          options?.map((ele, i) => (
            <option selected={i === 0} key={ele.id} value={ele.id}>
              {ele.name}
            </option>
          ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;
