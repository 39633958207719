import { CircularProgress, CustomButtonOne } from "@components";
import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import GiftIcon from "@material-ui/icons/Redeem";
import { getFloatDetailsById } from "@store/actions/floatAction";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/icons/arrow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    background: "#fff",
    borderRadius: 8,
    width: "100%",
  },
  containerStyle: {
    height: "469px",
    [theme.breakpoints.down(700)]: {
      height: "380px",
    },
    [theme.breakpoints.down(570)]: {
      height: "280px",
    },
  },
  heroImg: {
    width: "100%",
    objectFit: "fill",
    height: "100%",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  heroDeails: {
    padding: "39px 52px",
  },
  workDeails: {
    padding: "39px 20px",
    background: theme.palette.primary.main,
    height: "100%",
  },
  spotsColor: {
    color: theme.palette.primary.main,
  },
  giftText: {
    position: "relative",
  },
  giftIcon: {
    position: "absolute",
    top: 2,
    left: 0,
    background: theme.palette.primary.main,
    borderRadius: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 15,
    height: 15,
  },
}));

function HeroSection({ type, data, getFloatDetailsById }) {
  const classes = useStyles();
  const [floatDetails, setFloatDetails] = React.useState(null);
  const joinedInpercentage = Math.round(
    (data?.results[0]?.joined_odds * 100) / data?.results[0]?.odds
  );

  React.useEffect(() => {
    if (data) {
      getFloatDetailsById(data?.results[0]?.id)
        .then((res) => {
          setFloatDetails(res.data?.results[0]);
        })
        .catch((err) => console.log(err));
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Grid container style={{ padding: "0px" }}>
        <Grid item xs={12} md={8} className={classes.containerStyle}>
          {type === "float" && (
            <img
              src={
                data?.results[0]?.images[0]?.image ||
                "http://placehold.it/821x469"
              }
              alt={data?.results[0]?.name}
              className={classes.heroImg}
            />
          )}
          {type === "works" && (
            <img
              src={"/images/about-banner.png"}
              alt={data?.results[0]?.name}
              className={classes.heroImg}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {type === "float" && (
            <div className={classes.heroDeails}>
              <Typography variant="h4">
                Win {data?.results[0]?.name} in Rs.
                {data?.results[0]?.joining_float_price} risk-free
              </Typography>
              {/* <Typography variant="subtitle1" style={{ margin: "25px 0" }}>
                <span className={classes.spotsColor}>
                  {data?.results[0]?.odds - data?.results[0]?.joined_odds}{" "}
                  Donation spots left
                </span>{" "}
                out of {data?.results[0]?.odds}
              </Typography> */}

              {floatDetails?.feature_expiry_date && (
                <Typography variant="h6" style={{ margin: "25px 0" }}>
                  Time remaining:{" "}
                  {formatDistanceToNow(
                    new Date(floatDetails?.feature_expiry_date)
                  )}
                </Typography>
              )}

              <div className={classes.giftText}>
                <div className={classes.giftIcon}>
                  <GiftIcon style={{ color: "#fff", fontSize: 14 }} />
                </div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: "bold", marginLeft: 20 }}
                >
                  Donate to join this Float.
                </Typography>
              </div>

              <Grid
                container
                alignItems="center"
                justify="space-between"
                style={{ marginTop: 20 }}
              >
                <CustomButtonOne
                  component={Link}
                  to={`/float-details/${data?.results[0]?.id}`}
                >
                  Donate now
                </CustomButtonOne>

                <CircularProgress value={joinedInpercentage} />
              </Grid>
            </div>
          )}

          {type === "works" && (
            <div className={classes.workDeails}>
              <Grid container spacing={2} style={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Find a Float
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Select your Charity
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Donate
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Invite Friends
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Win Float
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ margin: "10px 0" }}
                  >
                    <Avatar
                      src={arrowIcon}
                      alt="arrow"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#fff", marginLeft: 10 }}
                    >
                      Use FloatBucks
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default connect(null, { getFloatDetailsById })(HeroSection);
