import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Container,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "67px 0px 100px 0px",
  },
  cardDetails: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    borderRadius: 8,
    padding: "18px 60px",
    minHeight: 197,
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      padding: "10px",
    },
  },
}));

function WorksMembers({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ textAlign: "center", marginBottom: 50 }}
        >
          {data.title}
        </Typography>
        <div className={classes.cardDetails}>
          {/* <Grid container alignItems="center" justify="space-around">
                        {[1, 2, 3, 4, 5].map(user => (
                            <Avatar 
                                key={user}
                                src=""
                                alt="user name"
                                style={{
                                    width: 155,
                                    height: 155
                                }}
                            />
                        ))}
                    </Grid> */}
          <img src={data.image} alt="winners" style={{ width: "100%" }} />
        </div>
        <div>
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ marginTop: 50 }}
          >
            {data.quotes}
          </Typography>
        </div>
      </Container>
    </div>
  );
}

export default WorksMembers;
