import * as actions from '../actionTypes';

const initialState = {
    currentUser: null,
    userProfile: null,
    token: null,
    details: null
}

export default (state=initialState, action) => {
    switch (action.type) {
        case actions.STORE_LOGGEDIN_USER_CREDS:
            return {
                ...state,
                currentUser: action.payload.user,
                token: action.payload.user.access_token
            }
        case actions.STORE_LOGGEDIN_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload
            }
        case actions.CLEAR_LOGGEDIN_USER_CREDS:
            return {
                ...state,
                currentUser: null,
                userProfile: null,
                token: null
            }
        case actions.STORE_LOGGEDIN_USER_DETAILS:
            return {
                ...state,
                details: action.payload
            }
        case actions.REFRESH_USER_TOKEN:
            return {
                ...state,
                token: action.payload.token
            }
        default:
            return state;
    }
}