import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "100px 0",
  },
  detailsWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardDetails: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    borderRadius: 8,
    padding: "40px 60px",
    zIndex: 999,
  },
  cardImage: {
    width: "100%",
    objectFit: "contain",
    borderRadius: "8px",
    "& img": {
      width: "100%",
      // height: '100%',
      objectFit: "fill",
      borderRadius: 8,
      height: "450px",
    },
  },
}));

function WinnerCard({ quotes, data }) {
  const classes = useStyles();
  console.log({ data });
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          container
          direction={quotes === "left" ? "row" : "row-reverse"}
          component={Link}
          to={`/winner-details/${data?.id}`}
        >
          <Grid item xs={12} md={7}>
            <div
              className={classes.detailsWrapper}
              style={{
                width: quotes === "left" ? "calc(100% + 72px)" : "100%",
                marginLeft: quotes === "left" ? 0 : -72,
              }}
            >
              <div className={classes.cardDetails}>
                <Typography variant="h4" color="textPrimary">
                  {data?.product_id?.name}
                </Typography>
                <Scrollbars style={{ width: "100%", minHeight: 100 }}>
                  <div style={{ paddingRight: 15 }}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      style={{ margin: "15px 0" }}
                    >
                      {data?.user_comment}
                    </Typography>
                  </div>
                </Scrollbars>
                <div>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ marginTop: 57, textAlign: "right" }}
                  >
                    {data?.user_id?.first_name}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={classes.cardImage}>
              <img
                src={data?.images[0]?.image || "http://placehold.it/448x526"}
                alt={"winner"}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default WinnerCard;
