import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Avatar, IconButton, Typography } from "@material-ui/core";
import CustomButtonOne from "@components/CustomButtonOne";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { connect } from "react-redux";
import {
  increaseQantity,
  decreaseQantity,
  deleteItem,
} from "@store/actions/addCart";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  headStyle: {
    background: theme.palette.primary.main,
    borderRadius: "8px 8px 0px 0px",
    color: "#fff",
  },
  wrapper: {
    background: "#fff",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 8,
    minHeight: 339,
    overflowX: "auto",
  },
  cellStyle: {
    ...theme.typography.h6,
    color: "#fff",
  },
  bodyCellStyle: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
  },
  qtyStyle: {
    background: "#EDEDED",
    width: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 30,
  },
  iconBtnStyle: {
    width: 25,
    height: 25,
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

function CartTable({ data, increaseQantity, decreaseQantity, deleteItem }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.headStyle}>
            <TableCell
              className={classes.cellStyle}
              style={{ borderTopLeftRadius: 8 }}
              align="left"
            >
              #
            </TableCell>
            <TableCell className={classes.cellStyle} align="center">
              Title
            </TableCell>
            <TableCell className={classes.cellStyle} align="center">
              Image
            </TableCell>
            <TableCell className={classes.cellStyle} align="center">
              Quantity
            </TableCell>
            <TableCell className={classes.cellStyle} align="center">
              Price
            </TableCell>
            <TableCell
              className={classes.cellStyle}
              style={{ borderTopRightRadius: 8 }}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.cartList?.map((row) => (
            <TableRow key={row.name}>
              <TableCell
                className={classes.bodyCellStyle}
                component="th"
                scope="row"
              >
                {row.id}
              </TableCell>
              <TableCell className={classes.bodyCellStyle} align="center">
                {row.product?.name}
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={
                      row.product?.images?.length > 0 &&
                      row.product?.images[0]?.image
                    }
                    alt={row.product?.name}
                    variant="square"
                    style={{
                      width: 98,
                      height: 49,
                    }}
                  />
                </div>
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.qtyStyle}>
                    <IconButton
                      className={classes.iconBtnStyle}
                      onClick={() => decreaseQantity(row?.id)}
                    >
                      <RemoveIcon style={{ color: "#fff" }} />
                    </IconButton>
                    <Typography>{row.quantity}</Typography>
                    <IconButton
                      className={classes.iconBtnStyle}
                      onClick={() => increaseQantity(row?.id)}
                    >
                      <AddIcon style={{ color: "#fff" }} />
                    </IconButton>
                  </div>
                </div>
              </TableCell>

              <TableCell className={classes.bodyCellStyle} align="center">
                {row.totalPrice}
              </TableCell>

              <TableCell align="center">
                <CustomButtonOne
                  style={{
                    width: 103,
                    height: 30,
                  }}
                  onClick={() => deleteItem(row?.id)}
                >
                  Delete
                </CustomButtonOne>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const actions = {
  increaseQantity,
  decreaseQantity,
  deleteItem,
};

export default connect(null, actions)(CartTable);
