import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import qs from "query-string";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  twoDots: {
    width: 32,
    height: 8,
    borderRadius: 5,
    background: theme.palette.primary.main,
    margin: "0px 5px",
  },
  twoDotsSmall: {
    width: 24,
    height: 4,
    borderRadius: 2,
    background: theme.palette.primary.main,
    margin: "0px 5px",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 20,
    background: theme.palette.primary.main,
    margin: "0px 5px",
  },
  dotSmall: {
    width: 4,
    height: 4,
    borderRadius: 20,
    background: theme.palette.primary.main,
    margin: "0px 5px",
  },
}));

function Dotted({ size, data }) {
  const classes = useStyles();
  const [activePageNum, setPageNum] = React.useState(1);
  const [pages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      if (data?.next) {
        const parsed = qs.parseUrl(data?.next);
        const pn = +parsed?.query.page - 1;
        setPageNum(pn);
      } else {
        const parsed = qs.parseUrl(data?.previous);
        if (parsed.query.page) {
          const pn = +parsed?.query.page + 1;
          setPageNum(pn);
        } else {
          setPageNum(activePageNum + 1);
        }
      }
      // else if (!data?.next && data?.previous) {
      //   const parsed = qs.parseUrl(data?.previous);
      //   if (parsed.query.page) {
      //     const pn = +parsed?.query.page + 1;
      //     setPageNum(pn);
      //   } else {
      //     setPageNum(activePageNum - 1);
      //   }
      // }
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      const parsed = qs.parseUrl(data?.next || data?.previous);

      if (data?.count % parseInt(parsed.query.size) === 0) {
        const totalPage = data?.count / parsed.query.size;
        setTotalPages(Math.floor(totalPage));
      } else {
        const totalPage = data?.count / parseInt(parsed.query.size) + 1;
        setTotalPages(Math.floor(totalPage));
      }
    }
  }, [data]);

  const renderDots = () => {
    if (data) {
      const pageNumbers = [];
      for (let i = 1; i <= pages; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers.map((ele) => (
        <div
          key={ele}
          className={clsx([size === "small" ? classes.dotSmall : classes.dot])}
          style={{
            width: ele === activePageNum ? 32 : 8,
          }}
        />
      ));
    }
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Typography
          variant="body2"
          color="textPrimary"
          style={{ pointer: "cursor", marginLeft: 5 }}
        >
          01
        </Typography>
        {renderDots()}
        {/* <div
          className={size === "small" ? classes.twoDotsSmall : classes.twoDots}
        ></div>
        <div
          className={size === "small" ? classes.dotSmall : classes.dot}
        ></div>
        <div
          className={size === "small" ? classes.dotSmall : classes.dot}
        ></div>
        <div
          className={size === "small" ? classes.dotSmall : classes.dot}
        ></div>
        <div
          className={size === "small" ? classes.dotSmall : classes.dot}
        ></div> */}

        <Typography
          variant="body2"
          color="textPrimary"
          style={{ pointer: "cursor", marginRight: 5 }}
        >
          {pages > 9 ? pages : "0" + pages}
        </Typography>
      </Grid>
    </div>
  );
}

export default Dotted;
