import React from "react";
import { makeStyles, Typography, Grid, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "100px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "50px 0",
    },
  },
  detailsWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardDetails: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    minHeight: 438,
    borderRadius: 8,
    padding: "40px 60px",
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      padding: "15px 20px",
    },
  },
  cardImage: {
    width: "100%",
    height: 438,
    objectFit: "contain",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      //   width: "100%",
      //   height: "100%",
      objectFit: "cover",
      borderRadius: 8,
    },
  },
}));

function WorksCard({ quotes, data }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          container
          direction={quotes === "left" ? "row" : "row-reverse"}
          spacing={4}
        >
          <Grid item xs={12} md={6}>
            <div className={classes.detailsWrapper}>
              <div className={classes.cardDetails}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{ textAlign: "center", marginBottom: 50 }}
                >
                  {data.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{ margin: "15px 0" }}
                >
                  {data.quote}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.cardImage}>
              <img src={data.image} alt={data.title} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default WorksCard;
