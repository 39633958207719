import { toastr } from "react-redux-toastr";
import axios from "../../config/axios";
import * as actions from "../actionTypes";
import { clearCartItems } from "./addCart";

// register user
export const registerUser = (creds, history) => {
  return (dispatch) => {
    dispatch({ type: actions.ASYNC_START_LOADING });

    const newUser = {
      first_name: creds.name,
      email: creds.email,
      password: creds.password,
    };

    axios
      .post("/auth/signup/", newUser)
      .then((res) => {
        toastr.success("Successfully registered!");
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
        history.push("/login");
      })
      .catch((err) => {
        console.log(err.response.data);
        toastr.error(err.response.data["email"][0]);
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
      });
  };
};

// login user
export const loginUser = (creds, history) => {
  return (dispatch, getState) => {
    dispatch({ type: actions.ASYNC_START_LOADING });
    const redirectUrl = getState().cart?.redirectUrl;
    const user = {
      username: creds.email,
      password: creds.password,
    };

    axios
      .post("/auth/login/", user)
      .then((res) => {
        dispatch({
          type: actions.STORE_LOGGEDIN_USER_CREDS,
          payload: {
            user: res.data,
          },
        });

        return res.data;
      })
      .then((user) => {
        dispatch(getUserProfile(user.access_token));
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });

        if (redirectUrl?.status) {
          history.push(redirectUrl?.url);
          dispatch({ type: actions.CLEAR_REDIRECT_URL });
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err.response?.data);
        toastr.error(err.response?.data?.non_field_errors[0]);
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
      });
  };
};

// login user with facebook
export const loginUserWithFacebook = (access_token, history) => {
  return (dispatch, getState) => {
    const redirectUrl = getState().cart?.redirectUrl;
    const data = {
      access_token: access_token,
    };

    console.log("caalinnnn");
    axios
      .post("/auth/facebook/", data)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: actions.STORE_LOGGEDIN_USER_CREDS,
          payload: {
            user: res.data,
          },
        });

        return res.data;
      })
      .then((user) => {
        dispatch(getUserProfile(user.access_token));

        if (redirectUrl?.status) {
          history.push(redirectUrl?.url);
          dispatch({ type: actions.CLEAR_REDIRECT_URL });
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err.response);
        // toastr.error(err.response?.data?.non_field_errors[0]);
      });
  };
};

// get profile
export const getUserProfile = (token) => {
  return (dispatch) => {
    return axios
      .get("/auth/user/detail", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: actions.STORE_LOGGEDIN_USER_PROFILE,
          payload: res.data.results[0],
        });
        dispatch(getUserDetails(res.data.results[0]?.id, token));
      })
      .catch((err) => console.log(err));
  };
};

// get user details
export const getUserDetails = (userId, token) => {
  return (dispatch) => {
    return axios
      .get(`/setting/detail-info/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: actions.STORE_LOGGEDIN_USER_DETAILS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// get user product list histoy
export const getUserProductHistory = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return axios.get(`setting/product-history/list?size=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
};

// get user product buy history
export const getUserPurchaseHistory = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return axios.get(`setting/buy-history/list?size=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
};

// get user reviews
export const getUserReviewList = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return axios.get(`/setting/review/list?size=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
};

export const getNextOrPrevPageListOfUserAccounts = (url) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
};

// logout user
export const logoutUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: actions.ASYNC_START_LOADING });
    const token = getState().auth?.currentUser?.access_token;
    axios
      .post("/auth/logout/", { token })
      .then((res) => {
        dispatch({ type: actions.CLEAR_LOGGEDIN_USER_CREDS });
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
        dispatch(clearCartItems());
      })
      .catch((err) => {
        console.log(err.response?.data);
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
      });
  };
};

// refresh token
export const refreshToken = (history) => {
  return (dispatch, getState) => {
    const token = getState().auth?.currentUser?.refresh_token;

    if (token) {
      axios
        .post("auth/token/refresh/", {
          refresh: token,
        })
        .then((res) => {
          dispatch({
            type: actions.REFRESH_USER_TOKEN,
            payload: {
              token: res.data.access,
            },
          });
        })
        .catch((err) => console.log(err));
    }
  };
};

// get my profile stats
export const getProfileState = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (token) {
      return axios.get("/profile/my-profile-stats/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
  };
};

// get my participation-floats
export const getParticipationFloats = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (token) {
      return axios.get("/profile/my-participation-floats/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
  };
};

// get my won floats
export const getWonFloats = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (token) {
      return axios.get("/profile/my-won-floats/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
  };
};

// get my completed floats
export const getCompletedFloats = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (token) {
      return axios.get("/profile/my-completed-floats/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
  };
};

// get my gift floats
export const getGiftFloats = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    if (token) {
      return axios.get("/profile/my-gift-floats/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
  };
};

// update user details
export const updateUserProfilePhoto = (file) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    const userId = getState().auth.userProfile?.id;

    let formData = new FormData();
    formData.append("avatar", file);

    axios
      .patch(`/setting/detail-info/user/${userId}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
          dispatch({
            type: actions.UPLOAD_PERCENTAGE,
            payload: {
              progress: percentCompleted,
            },
          });
        },
      })
      .then((res) => {
        console.log(res.data);
        toastr.success("Successfully updated!");
        dispatch({ type: actions.CLEAR_UPLOAD_PERCENTAGE });

        dispatch(getUserProfile(token));
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.CLEAR_UPLOAD_PERCENTAGE });
        if (err.response?.data?.code === "token_not_valid") {
          refreshToken();
        }
      });
  };
};

// update user shipping method
export const updateShippingMethod = (creds) => {
  return (dispatch, getState) => {
    dispatch({ type: actions.ASYNC_START_LOADING });

    const token = getState().auth.token;
    const userId = getState().auth.userProfile?.id;
    axios
      .patch(`/setting/shipping-info/user/${userId}`, creds, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data);
        toastr.success("Successfully updated!");
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });

        dispatch(getUserProfile(token));
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: actions.ASYNC_FINISHED_LOADING });
        if (err.response?.data?.code === "token_not_valid") {
          refreshToken();
        }
      });
  };
};
