import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    btnStyle: {
        ...theme.typography.caption,
        background: theme.palette.primary.main,
        width: 168,
        height: 40,
        textTransform: 'uppercase',
        color: '#fff',
        borderRadius: 41,
        '&:hover': {
            background: theme.palette.primary.main,
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.25)'
        }
    }
}));

function CustomButtonTwo({ children, ...rest }) {
    const classes = useStyles();
    return (
        <Button
            {...rest}
            className={classes.btnStyle}
        >
            {children}
        </Button>
    )
}

export default CustomButtonTwo;