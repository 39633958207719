import React, { forwardRef } from "react";
import { makeStyles, Typography } from "@material-ui/core";
// import 'react-phone-number-input/style.css';
import { Controller } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  inputStyle: {
    width: "100%",
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      "&::placeholder": {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
  },
}));

const CustomPhoneInput = forwardRef((props, ref) => {
  const classes = useStyles();
  const { control, errors, label } = props;
  // const [dc, setDc] = React.useState("US");

  // React.useEffect(() => {
  //   axios
  //     .get("https://ipapi.co/country")
  //     .then((res) => setDc(res.data))
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <div className={classes.wrapper}>
      {label && (
        <Typography
          variant="subtitle1"
          color="textPrimary"
          style={{ marginBottom: "10px" }}
        >
          {label}
        </Typography>
      )}
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <MuiPhoneNumber
            {...field}
            defaultCountry={"UK"}
            // onlyCountries={codes}
            style={{ width: "100%" }}
            // label="Enter phone number"
            variant="outlined"
            margin="normal"
            error={Boolean(errors.phone)}
            autoComplete="off"
            className={classes.inputStyle}
          />
        )}
      />
    </div>
  );
});
export default CustomPhoneInput;
