import * as actions from "../actionTypes";

export const addToCart = (product) => {
  return (dispatch, getState) => {
    const prevData = getState().cart.cartList;
    const isAdded = prevData?.some((ele) => ele.id === product?.id);
    if (!isAdded) {
      const data = [...prevData];
      const newItem = {
        id: product?.id,
        product,
        totalPrice: product?.price,
        quantity: 1,
      };
      data.push(newItem);

      dispatch({
        type: actions.ADD_TO_CART,
        payload: data,
      });
    }
  };
};

export const increaseQantity = (productId) => {
  return (dispatch, getState) => {
    const prevData = getState().cart.cartList;
    const isAdded = prevData?.some((ele) => ele.id === productId);
    if (isAdded) {
      const data = [...prevData];
      const productIndex = prevData.findIndex((ele) => ele.id === productId);

      if (productIndex !== -1) {
        data[productIndex] = {
          ...data[productIndex],
          quantity: data[productIndex]?.quantity + 1,
          totalPrice:
            data[productIndex]?.product?.price *
            (data[productIndex]?.quantity + 1),
        };
      }
      dispatch({
        type: actions.ADD_TO_CART,
        payload: data,
      });
      subTotalCalc();
    }
  };
};

export const decreaseQantity = (productId) => {
  return (dispatch, getState) => {
    const prevData = getState().cart.cartList;
    const isAdded = prevData?.some((ele) => ele.id === productId);
    if (isAdded) {
      const data = [...prevData];
      const productIndex = prevData.findIndex((ele) => ele.id === productId);

      if (productIndex !== -1 && data[productIndex]?.quantity > 1) {
        data[productIndex] = {
          ...data[productIndex],
          quantity: data[productIndex]?.quantity - 1,
          totalPrice:
            data[productIndex]?.product?.price *
            (data[productIndex]?.quantity - 1),
        };
      }
      dispatch({
        type: actions.ADD_TO_CART,
        payload: data,
      });
      subTotalCalc();
    }
  };
};

export const deleteItem = (productId) => {
  return (dispatch, getState) => {
    const prevData = getState().cart.cartList;
    const isAdded = prevData?.some((ele) => ele.id === productId);
    if (isAdded) {
      const data = [...prevData];
      const productIndex = prevData.findIndex((ele) => ele.id === productId);
      console.log(productIndex);
      if (productIndex !== -1) {
        data.splice(productIndex, 1);
      }
      dispatch({
        type: actions.ADD_TO_CART,
        payload: data,
      });
      subTotalCalc();
    }
  };
};

export const subTotalCalc = () => {
  return (dispatch, getState) => {
    const cartData = getState().cart.cartList;
    let subTotalPrice = 0;
    let discountPrice = 0;
    cartData?.forEach((item) => {
      subTotalPrice += item?.totalPrice;
      if (item?.product?.has_discount_offer) {
        if (item?.product?.discount_start_date !== null) {
          const startDate = new Date(item?.product?.discount_start_date);
          const currentDate = new Date(Date.now());
          if (currentDate >= startDate) {
            discountPrice +=
              item?.product?.price *
              item?.quantity *
              (item?.product?.has_discount_value / 100);
          }
        } else {
          discountPrice +=
            item?.product?.price *
            item?.quantity *
            (item?.product?.has_discount_value / 100);
        }
      }
    });
    dispatch({
      type: actions.SUBTOTAL_PRICE,
      payload: {
        subTotalPrice,
        discountPrice,
      },
    });
  };
};

export const useFloatBucksHandler = (bucks) => {
  return (dispatch) => {
    console.log({ bucks });
  };
};

export const grandTotalCalc = () => {
  return (dispatch, getState) => {
    const subTotal = getState().cart.subTotal;
    const discount = getState().cart.discount;
    const appliedFloatBucks = getState().cart.appliedFloatBucks;

    let grandTotal = subTotal - discount;

    dispatch({
      type: actions.GRANDTOTAL_PRICE,
      payload: grandTotal,
    });
  };
};

export const setRedirectUrl = (url) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_REDIRECT_URL,
      payload: {
        status: true,
        url,
      },
    });
  };
};

export const clearRedirectUrl = () => {
  return (dispatch) => {
    dispatch({ type: actions.CLEAR_REDIRECT_URL });
  };
};

export const clearCartItems = () => {
  return (dispatch) => {
    dispatch({ type: actions.CLEAR_CART_ITEMS });
  };
};
