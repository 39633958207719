import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

import { CustomButtonOne, CircularProgress } from "@components";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.15)",
    width: "100%",
    borderRadius: 8,
    height: "453px",
    position: "relative",
    [theme.breakpoints.down("500")]: {
      width: "100%",
    },
  },
  imgStyle: {
    width: "100%",
    objectFit: "fill",
    borderRadius: "8px 8px 0px 0px",
    height: "100%",
  },
  leftStyle: {
    color: theme.palette.text.primary,
  },
  actionArea: {
    padding: "0px 15px",
    boxSizing: "border-box",
    position: "absolute",
    bottom: 10,
    left: 0,
    width: "100%",
    height: "100%",
  },
  fixedWidth: {
    width: "361px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkMorefixedWidth: {
    width: "353px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function ComingSoonCard({ isFixedWidth, isCheckMore }) {
  const classes = useStyles();
  return (
    <motion.div
      className={clsx([
        classes.root,
        isFixedWidth && classes.fixedWidth,
        isCheckMore && classes.checkMorefixedWidth,
      ])}
      initial={{
        opacity: 0.7,
        transform: "scale(0.7)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        type: "tween",
      }}
    >
      <div className={classes.actionArea}>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: "100%" }}
        >
          <Typography variant="h6" color="textPrimary">
            Coming soon...
          </Typography>
        </Grid>
      </div>
    </motion.div>
  );
}

export default ComingSoonCard;
