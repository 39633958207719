import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import { Dotted } from "@components";

import Desc from "./Desc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  details: {
    background: "#fff",
    boxShadow: "8px 8px 17px rgba(0, 0, 0, 0.46)",
    borderRadius: 8,
    padding: "30px 45px",
  },
}));

function UnicefDetails({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <Typography
          variant="h5"
          color="primary"
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: 10,
          }}
        >
          {data?.name}
        </Typography>
        <Scrollbars style={{ width: "auto", height: 170 }}>
          <Desc data={data} />
        </Scrollbars>
        {/* <Typography
          variant="subtitle1"
          color="primary"
        >
          Read More
        </Typography> */}
      </div>
    </div>
  );
}

export default UnicefDetails;
